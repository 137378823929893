import * as React from "react"
import {Link} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"

export const AkamaruAuthor = () => {
  const link = "https://kyoda.co.jp/kyodacreation/staff/20201007317/";
  const {t} = useTranslation();

  return (
    <section>
      <h2>{t("akamaruAuthor.title")}</h2>
      <h3><strong>{t("akamaruAuthor.name")}</strong></h3>
      <p>{t("akamaruAuthor.profile.item1")}</p>
      <p>{t("akamaruAuthor.profile.item2")}</p>
      <p>{t("akamaruAuthor.profile.item3")}</p>

      <p>
        <Link to={link} target={"_blank"}>
          <p>{t("akamaruAuthor.companyName")}</p>
        </Link>
      </p>
    </section>
  )
}

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
