import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {useTranslation, I18nextContext} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"

const PurchasingButton = () => {
  const url = "https://www.kodo-mall.jp/view/category/akamaru";
  const param = new Array();
  const kv = [
    { key: "utm_source", value: "app"},
    { key: "utm_medium", value: "akamaru"},
    { key: "utm_campaign", value: "app-akamaru202302"},
  ];

  kv.forEach((e) => param.push(e.key + "=" + e.value));
  const link = url + "?" + param.join('&')

  const context = React.useContext(I18nextContext);
  const label = (context.language === 'jp') ? '公式サイトで購入する' : 'Buy Official Site';

  return (
    <p className="text-center">
      <Link className="btn btn-warning" to={link} target='_blank'>
        {label}
      </Link>
    </p>
  );
}

export const AkamaruSeries = () => {
  const link = "https://www.poplar.co.jp/book/search/result/archive/2078010.html";
  const {t} = useTranslation();

  return (
    <>
      <section>
        <>
          <h2 itemProp="name">{t("akamaruSeries.section1.title")}</h2>

          <p>{t("akamaruSeries.section1.item1")}</p>
          <p>{t("akamaruSeries.section1.item2")}</p>

          <PurchasingButton />

          <StaticImage src="../../images/akamaru/akamaru_introduction.png" alt="「あかまる」について" />
        </>

        <>
          <h2 itemProp="name">{t("akamaruSeries.section2.title")}</h2>

          <p>{t("akamaruSeries.section2.item1")}</p>
          <p>{t("akamaruSeries.section2.item2")}</p>
          <p>{t("akamaruSeries.section2.item3")}</p>
        </>


        <p>
          <Link to={link} target={"_blank"} dangerouslySetInnerHTML={{__html: t("akamaruSeries.publisherName")}} />
        </p>
      </section>
    </>
  )
}

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


