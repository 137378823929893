import * as React from "react"
import Seo from "../../components/seo"
import AppLayout from "../../components/app/app-layout"
import {AkamaruSeries} from "../../components/akamaru/series";
import {AkamaruAuthor} from "../../components/akamaru/author";
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"

const About = () => {
  const {t} = useTranslation();
  const title = t("akamaruSeries.title");

  return (
    <AppLayout>
      <Seo title={title} isIndex={false} pagePath={"/product/akamaru"}/>
      <article className="container page-article-main">
        <h1>{title}</h1>
        <AkamaruSeries />
        <AkamaruAuthor />
      </article>
    </AppLayout>
  );
}

export default About

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
